import React from 'react';
import uniqid from 'uniqid';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getArticleContent } from 'SharedPartialsLocal/getArticleContent';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { RegPlateForm } from '@autovia-uk/polaris-components/components/molecules/RegPlateForm';
import { ArticleGroup } from '@autovia-uk/polaris-components/components/organisms/ArticleGroup';
import { FiltersBlock } from '@autovia-uk/polaris-components/components/organisms/FiltersBlock';
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter';
import { getDealsBlock } from '@autovia-uk/polaris-components/components/organisms/DealsBlock/getDealsBlock';
import { GycSmcPromoBlock } from 'Molecules/GycSmcPromoBlock';

/**
 * The block types which can be combined (one row with two blocks).
 * @type {string[]}
 */
const blockTypesWhichCanBeCombined = ['valueMyCar', 'newsLetter', 'gycPromoBlock'];

/**
 * Generates the content for the "Value My Car" block.
 *
 * @param {Object} regPlateFormConfigType - The configuration object for the "Value My Car" block.
 * @param {string} regPlateFormConfigType.title - The title for the block.
 * @param {string} regPlateFormConfigType.description - The description for the block.
 * @param {Object} regPlateFormConfigType.regPlateFormProps - The properties for the `RegPlateForm` component.
 * @returns {JSX.Element} The JSX element representing the "Value My Car" block.
 */
const valueMyCarContent = regPlateFormConfigType => (
  <div className="polaris__value-my-car">
    <div className="polaris__smc-promo--logo" />
    <div className="polaris__value-my-car--title">
      <p className="-no-spacing">{regPlateFormConfigType?.title}</p>
    </div>
    <div className="polaris__value-my-car--description">
      <p className="-no-spacing">{regPlateFormConfigType?.description}</p>
    </div>
    <RegPlateForm
      submitTypeIsButton
      {...regPlateFormConfigType?.regPlateFormProps}
    />
  </div>
);

/**
 * Generates the content for the "GYC Promo Block".
 *
 * @param {Object} gycPromoBlockConfig - The configuration object for the "GYC Promo Block".
 * @param {string} gycPromoBlockConfig.title - The title for the block.
 * @param {string} gycPromoBlockConfig.description - The description for the block.
 * @param {string} gycPromoBlockConfig.ctaUrl - The URL for the call-to-action link.
 * @param {string} gycPromoBlockConfig.ctaLabel - The label for the call-to-action link.
 * @returns {JSX.Element} The JSX element representing the "GYC Promo Block".
 */

const gycPromoBlockContent = gycPromoBlockConfig => (
  <div className="polaris__gyc-promo-block">
    <div className="polaris__gyc-promo--logo" />
    <p className="polaris__gyc-promo-block--title">
      {gycPromoBlockConfig?.title}
    </p>
    <p className="polaris__gyc-promo-block--description">
      {gycPromoBlockConfig?.description}
    </p>
    <Link
      extraClassNames={{
        '-link-as-button': true,
        'polaris__gyc-promo-block--cta': true,
      }}
      href={gycPromoBlockConfig?.ctaUrl}
      onClick={() => {
        window.dataLayer = window.dataLayer || [];
        if (gycPromoBlockConfig?.trackingData) {
          window.dataLayer.push(gycPromoBlockConfig.trackingData);
        }
      }}
    >
      {gycPromoBlockConfig?.ctaLabel}
    </Link>
  </div>
);

/**
 * Returns the properties for an article card based on the layout type.
 *
 * @param {string} layoutType - The layout type of the article card.
 * @returns {Object} The properties for the article card.
 */
const getArticleCardProps = (layoutType) => {
  const defaultProps = {
    articleCardStyle: 'default',
    extraClassNames: {},
    articlesPerRow: 4,
  };

  const defaultArticleCardProps = {
    excerpt: null,
    datePosition: 'bottom',
    kickerPosition: 'bottom',
    showRating: true,
    ratingPosition: 'after-title',
  };

  const reversedHeroandContent = layoutType === 'Filters Block Blue';
  const backgroundColorBlue = layoutType === 'Filters Block Blue';

  switch (layoutType) {
    case 'Hero Feature':
      return {
        ...defaultProps,
        articleCardStyle: 'featured',
        articlesPerRow: 2,
        articleCardProps: {
          ...defaultArticleCardProps,
          ratingPosition: 'over-image',
        },
      };
    case 'Hero List':
      return {
        ...defaultProps,
        extraClassNames: { '-latest-featured': true },
        articlesPerRow: 1,
        title: null,
        ctaUrl: null,
        articleCardProps: {
          ...defaultArticleCardProps,
          excerpt: null,
          image: null,
          ratingPosition: 'after-title',
        },
      };
    case 'Row':
      return {
        ...defaultProps,
        articleCardProps: {
          ...defaultArticleCardProps,
          ratingPosition: 'over-image',
        },
        extraClassNames: { '-home-bottom-articles': true, '-is-scrollable': true },
      };
    case 'Double List':
      return {
        ...defaultProps,
        articleCardStyle: 'side',
        extraClassNames: { '-alt-header': true, '-alt-side': true },
        articlesPerRow: 1,
        articleCardProps: {
          ...defaultArticleCardProps,
          datePosition: 'meta',
          showRating: true,
          ratingPosition: 'after-title',
        },
      };
    case 'Feature and Short List':
    case 'Filters Block Default':
    case 'Filters Block Blue':
      return {
        ...defaultProps,
        articleCardProps: {
          ...defaultArticleCardProps,
        },
        articleCardStyle: 'side',
        extraClassNames: {
          '-alt-featured': true,
          '-reverse-block': reversedHeroandContent,
          '-background-color-blue': backgroundColorBlue,
        },
      };
    case 'mostPopular':
      return {
        ...defaultProps,
        articleCardProps: {
          ...defaultArticleCardProps,
          image: null,
          ratingPosition: 'after-title',
          showIndex: true,
        },
        articleCardStyle: 'minimal',
        articlesPerRow: 5,
        extraClassNames: {
          'polaris__home--popular': true,
          '-home-popular': true,
        },
        title: 'Most popular',
      };
    case 'Four Up Row Double':
      return {
        ...defaultProps,
        extraClassNames: { '-four-up-row-double': true, '-is-scrollable': true },
        articleCardProps: {
          ...defaultArticleCardProps,
        },
      };
    default:
      return {
        ...defaultProps,
        articleCardProps: {
          ...defaultArticleCardProps,
        },
      };
  }
};

/**
 * Returns a React component for an article group based on the provided content data.
 *
 * @param {Object} contentData - The data for the content.
 * @returns {React.Element|null} The React component for the article group or null if no content data is provided.
 */
const getArticleGroup = (contentData) => {
  if (!contentData) return null;

  const {
    content,
    clientLogos,
    ctaLabel,
    ctaUrl,
    title: contentTitle,
    type,
  } = contentData;
  let { layoutType } = contentData;

  const contentItems = content.map(getArticleContent);

  // image in the first article should not be lazy loaded
  if (contentItems[0]) {
    contentItems[0].image.lazyLoad = false;
    contentItems[0].image.isLCPCandidate = true;
  }

  // Set layout type for most popular articles, so it can use the configuration set in the getArticleCardProps function
  if (type === 'mostPopular') {
    layoutType = 'mostPopular';
  }

  const {
    articleCardStyle,
    articleCardProps,
    articlesPerRow,
    extraClassNames,
    title: articleGroupTitle,
  } = getArticleCardProps(layoutType);

  // Use the content title if it exists, otherwise use the article group title, which is set in the configuration above
  const title = contentTitle || articleGroupTitle;

  // Assign extra class names for second (and plus) articles in Hero Feature layout
  if (layoutType === 'Hero Feature') {
    for (let i = 1; i < contentItems.length; i++) {
      contentItems[i].extraClassNames = {
        '-side': true,
      };
    }
  }

  if (title === 'Resources') {
    extraClassNames['-resources'] = true;
  }

  const image = clientLogos?.[0]?.src ? { src: clientLogos[0].src, alt: clientLogos[0].alt || '' } : {};
  if (image.src) extraClassNames['-is-sponsored'] = true;

  return (
    <ArticleGroup
      key={uniqid()}
      title={title || ''}
      articlesPerRow={articlesPerRow}
      articleCardStyle={articleCardStyle}
      content={contentItems}
      articleCardProps={articleCardProps}
      extraClassNames={extraClassNames}
      ctaLabel={ctaLabel}
      ctaUrl={ctaUrl}
      image={image}
    />
  );
};

/**
 * Returns the content block based on the provided type and configurations.
 *
 * @param {string} type - The type of the content block.
 * @param config
 * @returns {JSX.Element|null} The React component for the content block or null if the type is not recognized.
 */
const getBlockContentByType = (type, config) => {
  switch (type) {
    case 'newsLetter':
      return getNewsletter({
        ...config.newsletterConfig,
        extraClassNames: {
          ...config.newsletterConfig.extraClassNames,
          '-home': true,
        },
      });
    case 'valueMyCar':
      return valueMyCarContent(config?.regPlateFormConfig?.valueMyCarHomepageBlock);
    case 'gycPromoBlock':
      return gycPromoBlockContent(config?.gycConfig?.homepagePromoBlock);
    default:
      return null;
  }
};

/**
 * Generates a combined block of two content types.
 *
 * @param {string} currentItemType - The type of the current content block.
 * @param {string} nextItemType - The type of the next content block.
 * @param {Object} config - The configuration object for the content blocks.
 * @returns {JSX.Element} The JSX element representing the combined content blocks.
 */
const combinedBlocks = (currentItemType, nextItemType, config) => (
  <div className="polaris__content polaris__core-content -full-width polaris__combined-blocks">
    <div className="polaris__grid -with-columns-2">
      {getBlockContentByType(currentItemType, config)}
      {getBlockContentByType(nextItemType, config)}
    </div>
  </div>
);

/**
 * Modifies the associated content array by combining certain block types into a single block.
 *
 * @param {Array} associatedContentArr - The array of associated content objects.
 * @param {Object} config - The configuration object for the content blocks.
 * @returns {Array} The modified array of associated content objects.
 */
const modifyAssociatedContent = (associatedContentArr, config) => {
  const result = [];
  for (let i = 0; i < associatedContentArr.length; i++) {
    const currentItem = associatedContentArr[i];

    const isBlockTypeWhichCanBeCombined = blockTypesWhichCanBeCombined.includes(currentItem.type);
    const nextItem = associatedContentArr[i + 1];
    const hasNextBlockWhichCanBeCombined = nextItem && blockTypesWhichCanBeCombined.includes(nextItem.type);

    if (isBlockTypeWhichCanBeCombined && hasNextBlockWhichCanBeCombined) {
      result.push({
        layoutType: 'combinedBlocks',
        type: 'combinedBlocks',
        content: combinedBlocks(currentItem.type, nextItem.type, config),
      });
      i++;
    } else {
      result.push(currentItem);
    }
  }
  return result;
};

/**
 * Returns an array of React components for the homepage blocks based on the associated content and configuration.
 *
 * @param {Array} associatedContent - The associated content data.
 * @param {Object} config - The configuration for the homepage blocks.
 * @returns {Array|boolean} An array of React components for the homepage blocks or false if no associated content is provided.
 */
const getHomepageBlocks = (associatedContent, config) => {
  if (!associatedContent?.length) return false;

  const {
    expertQuote = {},
    regPlateFormConfig,
  } = config;

  let isFirstRow = false;
  const components = [];
  let adIndex = 1;

  // Modify the associated content array to combine certain block types into a single block
  const modifiedAssociatedContent = modifyAssociatedContent(associatedContent, config);

  // Inject the native ad into the 4th position of the content array and remove the last item
  // Note: Only inject in the first Row block encountered in the Homepage blocks
  const injectElement = (obj, idx, item) => {
    const insertIndex = Math.min(idx, obj.content.length);
    const modifiedContent = [...obj.content];
    // Remove the last item from the content array regardless of where the new item is inserted
    modifiedContent.splice(modifiedContent.length - 1, 1);
    // Insert the new item at the calculated index
    modifiedContent.splice(insertIndex, 0, item);
    return modifiedContent;
  };

  for (let i = 0; i < modifiedAssociatedContent.length; i++) {
    const content = modifiedAssociatedContent[i];
    const { layoutType, type } = content;
    const firstBlockClass = i === 0 ? ' -firstBlock' : '';

    // Add a class to the SMC Promo Block and Hero Feature if it is followed by a Hero Feature block
    let smcPromoBlockBeforeHeroFeatureClass = '';
    if (content.type === 'smcPromoBlock' && modifiedAssociatedContent[i + 1]?.layoutType === 'Hero Feature') {
      smcPromoBlockBeforeHeroFeatureClass = ' -smc-promo-block-before-hero-feature';
    }
    if (layoutType === 'Hero Feature' && modifiedAssociatedContent[i - 1]?.type === 'smcPromoBlock') {
      smcPromoBlockBeforeHeroFeatureClass = ' -smc-promo-block-before-hero-feature';
    }

    switch (layoutType) {
      case 'Hero Feature': {
        const nextContent = modifiedAssociatedContent[i + 1];
        components.push((
          <div className={`polaris__core-content polaris__content${firstBlockClass} polaris__hero-feature-and-hero-list-block${smcPromoBlockBeforeHeroFeatureClass}`}>
            {modifiedAssociatedContent[i].title && <div className="polaris__hero-feature-and-hero-list-block--title">{modifiedAssociatedContent[i].title}</div>}
            <div className="polaris__grid -with-columns-2-1">
              {getArticleGroup(content)}
              {nextContent && nextContent.layoutType === 'Hero List'
                && getArticleGroup(nextContent)}
            </div>
          </div>
        ));
        if (nextContent && nextContent.layoutType === 'Hero List') {
          i += 1; // Skip rendering the next block separately and falling into default case
        }
        break;
      }
      case 'Double List': {
        const nextContent = modifiedAssociatedContent[i + 1];
        components.push(
          <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
            <div className="polaris__grid -with-columns-2">
              {getArticleGroup(content)}
              {nextContent && nextContent.layoutType === 'Double List' && getArticleGroup(nextContent)}
            </div>
          </div>,
        );
        if (nextContent && nextContent.layoutType === 'Double List') i += 1;
        break;
      }
      case 'Row': {
        if (!isFirstRow) {
          isFirstRow = true;

          const nativeAd = {
            component: [
              <React.Fragment key="native_home">
                {getNativeAd({
                  id: 'native_home',
                  targeting: {
                    position: 'native_home',
                    placement: 'native_home',
                    refresh: 'no',
                  },
                })}
              </React.Fragment>,
            ],
          };

          const associatedContentWithNativeAd = { ...content, content: injectElement(content, 3, nativeAd) };
          components.push(
            <div className={`polaris__core-content polaris__content -has-native-ad${firstBlockClass}`}>
              {getArticleGroup(associatedContentWithNativeAd)}
            </div>,
          );
        } else {
          components.push(
            <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
              {getArticleGroup(content)}
            </div>,
          );
        }
        break;
      }
      case 'Filters Block Default':
      case 'Filters Block Blue': {
        const {
          articleCardStyle,
          articleCardProps,
          articlesPerRow,
          extraClassNames,
        } = getArticleCardProps(layoutType);

        components.push(
          <div className={`polaris__core-content polaris__content${firstBlockClass} -full-width`}>
            <FiltersBlock
              articleCardStyle={articleCardStyle}
              articleCardProps={articleCardProps}
              articlesPerRow={articlesPerRow}
              childrenContent={content.children}
              content={content.content}
              ctaLabel={content.ctaLabel}
              ctaUrl={content.ctaUrl}
              extraClassNames={extraClassNames}
              title={content.title}
            />
          </div>,
        );
        break;
      }
      default: {
        switch (type) {
          case 'adBlock': {
            const topAdClass = i === 0 ? ' polaris__below-header-ad-wrapper' : '';
            components.push(
              <div className={`polaris__content -homepageAdBlock -homepageAdBlock-${adIndex}${topAdClass}`} key={`adBlock-${adIndex}`}>
                <Ad
                  id={`refresh-inline_${adIndex}`}
                  isSkippable
                  isSpaceReserved
                  targeting={{ position: `inline_${adIndex}`, placement: `inline_${adIndex}`, refresh: 'yes' }}
                  type="slot-1"
                />
              </div>,
            );
            adIndex += 1;
            break;
          }
          case 'newsLetter': {
            components.push(
              <div className="polaris__content polaris__core-content -full-width">
                {getBlockContentByType('newsLetter', config)}
              </div>,
            );
            break;
          }
          case 'valueMyCar': {
            components.push(
              <div className="polaris__content polaris__core-content -full-width">
                {getBlockContentByType('valueMyCar', config)}
              </div>,
            );
            break;
          }
          case 'combinedBlocks': {
            components.push(content.content);
            break;
          }
          case 'widgetBlock': {
            const { html: widgetHtml } = content;
            if (widgetHtml) {
              components.push(<HtmlSafe html={widgetHtml} />);
            }
            break;
          }
          case 'smcPromoBlock': {
            components.push(
              <div className={`polaris__core-content polaris__content polaris__smc-promo-block -full-width${smcPromoBlockBeforeHeroFeatureClass}`}>
                <div className="polaris__smc-promo-block--value-my-car">
                  {valueMyCarContent(regPlateFormConfig?.smcPromoBlock)}
                </div>
                <div className="polaris__smc-promo-block--expert-quote">
                  <div className="polaris__smc-promo-block--expert-quote--inner">
                    <div className="polaris__smc-promo-block--expert-quote--media">
                      <Image
                        disableTransforms
                        {...expertQuote?.smcPromoBlock?.author?.image}
                      />
                    </div>
                    <div className="polaris__smc-promo-block--expert-quote--content">
                      <figure>
                        <blockquote>
                          {expertQuote?.smcPromoBlock?.text}
                        </blockquote>
                        <figcaption>
                          {expertQuote?.smcPromoBlock?.author?.name}
                          &nbsp;•&nbsp;
                          {expertQuote?.smcPromoBlock?.author?.jobTitle}
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>,
            );
            break;
          }
          case 'gycPromoBlock': {
            components.push(
              <div className={`polaris__core-content polaris__content${firstBlockClass} -full-width`}>
                {getBlockContentByType('gycPromoBlock', config)}
              </div>,
            );
            break;
          }
          case 'gycSmcPromoBlock': {
            components.push(
              <div className="polaris__content -full-width">
                <GycSmcPromoBlock />
              </div>,
            );
            break;
          }
          case 'dealsBlock': {
            components.push(
              <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
                {content?.newCarDeals && getDealsBlock({
                  ...content.newCarDeals,
                  config: config?.gycConfig?.dealsBlock?.homepage,
                  extraClassNames: {
                    '-home': true,
                  },
                  isScrollable: true,
                })}
              </div>,
            );
            break;
          }
          default: {
            components.push(
              <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
                {getArticleGroup(content)}
              </div>,
            );
            break;
          }
        }
        break;
      }
    }
  }

  return components;
};

export { getArticleGroup, getHomepageBlocks };
