const ONE_SECOND = 1000;

/**
 * Tracks a link click event asynchronously using Google Tag Manager dataLayer.
 * Returns a Promise that resolves after the event is tracked or times out.
 *
 * @param {Object} dataLayerPayload - The data to be pushed to GTM dataLayer
 * @returns {Promise<boolean>} A promise that resolves to true when the tracking is complete or times out
 * @example
 * await asyncTrackLinkClick({
 *   event: 'marketplace.button_click',
 *   'event.type': 'GYC',
 * });
 */
export const asyncTrackLinkClick = async (dataLayerPayload) => {
  // This promise will resolve once the eventCallback is invoked by GTM
  await new Promise(((resolve) => {
    window.dataLayer.push({
      ...dataLayerPayload,
      eventCallback: () => { resolve(true); },
      // eventTimeout is set to one second to ensure
      // the promise resolves even if the eventCallback is not invoked
      eventTimeout: ONE_SECOND,
    });
  }));
};
