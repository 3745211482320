import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';

import './_style.scss';

/**
 * @name GycSmcPromoBlock
 * @param {Object} context - The context object containing component configuration
 * @param {string} context.cssPrefix - CSS prefix for component styling
 * @param {Object} extraClassNames - Additional CSS class names to apply
 * @returns {JSX.Element} A promotional block component with GYC and SMC buttons
 */

/**
 * Pushes tracking data to the dataLayer array.
 *
 * @param {Object} trackingData - The tracking data to be pushed to the dataLayer.
 */
const onPromoBlockLinkClick = (trackingData) => {
  window.dataLayer = window.dataLayer || [];
  if (trackingData) {
    window.dataLayer.push(trackingData);
  }
};

const TRACKING_DATA = {
  GYC: {
    event: 'marketplace.button_click',
    'event.type': 'GYC',
    'marketplace_button.placement': 'gycSmcPromoBlock',
  },
  SMC: {
    event: 'marketplace.button_click',
    'event.type': 'SMC',
    'marketplace_button.placement': 'gycSmcPromoBlock',
  },
};

const GycSmcPromoBlock = ({
  context: {
    cssPrefix,
  },
  extraClassNames,
}) => (
  <div className={classNames({
    [`${cssPrefix}__gycsmc-promo-block`]: true,
    ...extraClassNames,
  })}
  >
    <p className={`${cssPrefix}__gycsmc-promo-block--title`}>Find a car with the experts</p>
    <div className={`${cssPrefix}__gycsmc-promo-block--buttons`}>
      <Link
        href="/buy-a-car"
        extraClassNames={{
          [`${cssPrefix}__gycsmc-promo-block--gyc`]: true,
        }}
        onClick={() => onPromoBlockLinkClick(TRACKING_DATA.GYC)}
      >
        Compare deals
      </Link>
      <Link
        href="/car-valuation"
        extraClassNames={{
          [`${cssPrefix}__gycsmc-promo-block--smc`]: true,
        }}
        onClick={() => onPromoBlockLinkClick(TRACKING_DATA.SMC)}
      >
        Value my car
      </Link>
    </div>
  </div>
);

GycSmcPromoBlock.propTypes = {
  context: PropTypes.shape(),
  extraClassNames: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
};

GycSmcPromoBlock.defaultProps = {
  context: {},
  extraClassNames: {},
};

export default withPolaris(GycSmcPromoBlock);
