/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'polaris-coreweb/exports';
import { getPaginatedTitle, toAbsolute } from 'Helpers';

const getHelmetForAuthor = (props) => {
  const {
    layoutData: {
      page: {
        author: {
          firstName,
          jobTitle,
          lastName,
          name,
          photo,
          shortBio,
          social,
        },
        url,
      },
    },
    pagination: {
      page: currentPage,
    },
    config: {
      globalSettings: {
        organisationName,
        postalAddress: {
          streetAddress,
          addressLocality,
          addressRegion,
          postalCode,
          addressCountry,
        },
      },
      siteUrl,
    },
  } = props;

  const strippedBio = shortBio ? shortBio.replace(/(<([^>]+)>)/gi, '') : '';

  return (
    <Helmet>
      <title>{getPaginatedTitle(name, currentPage)}</title>
      <script type="application/ld+json">
        {JSON.stringify({
          '@type': 'Person',
          '@context': 'http://schema.org/',
          '@id': url && toAbsolute(url, siteUrl),
          name,
          jobTitle: jobTitle || undefined,
          description: strippedBio,
          image: photo && photo.src ? {
            '@type': 'ImageObject',
            url: photo.src,
            height: photo.height || undefined,
            width: photo.width || undefined,
          } : undefined,
          url: url && toAbsolute(url, siteUrl),
          sameAs: (social && social.length > 0 && social.map(singleSocial => singleSocial.url)) || undefined,
          worksFor: {
            '@type': 'Organization',
            name: organisationName,
          },
          workLocation: {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              streetAddress,
              addressLocality,
              addressRegion,
              postalCode,
              addressCountry,
            },
          },
        })}
      </script>
      <meta property="twitter:description" content={strippedBio} />
      <meta property="twitter:title" content={name} />
      <meta property="og:type" content="profile" />
      <meta property="og:profile:first_name" content={firstName} />
      <meta property="og:profile:last_name" content={lastName} />
      {photo?.src ? <meta property="og:image" content={photo?.src} /> : ''}
      <meta property="og:description" content={strippedBio} />
      <meta property="og:title" content={name} />
    </Helmet>
  );
};

getHelmetForAuthor.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      author: PropTypes.shape({
        shortBio: PropTypes.string,
        firstName: PropTypes.string,
        jobTitle: PropTypes.string,
        lastName: PropTypes.string,
        name: PropTypes.string,
        photo: PropTypes.shape({
          height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          src: PropTypes.string,
          width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        social: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
        url: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  config: PropTypes.shape({
    globalSettings: PropTypes.shape({
      organisationName: PropTypes.string.isRequired,
      postalAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        addressLocality: PropTypes.string.isRequired,
        addressRegion: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        addressCountry: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
};

export default getHelmetForAuthor;
